const estadoInicial = {
    favoritos: false,
    categoria: [],
    pesquisar: ['todos', ''],
    livroPorPagina: 15,
    paginate: 15,
    ordenarPor: 'titulo_asc'
}

const filtroReducer = (state = estadoInicial, action) => {

    switch (action.type) {
        case 'GET_FAVORITOS':
            return {
                ...state,
                favoritos: action.favoritos
            }

        case 'GET_CATEGORIA':
            return {
                ...state,
                categoria: action.categoria
            }
        case 'GET_PESQUISAR':
            return {
                ...state,
                pesquisar: action.pesquisar
            }
        case 'GET_ORDERNAR_POR':
            return {
                ...state,
                ordenarPor: action.ordenarPor
            }
        case 'SET_QUANTIDADE':
            return {
                ...state,
                page: action.page
            }
        case 'LIMPAR_FILTRO':
            return {
                ...state,
                favoritos: false,
                categoria: [],
                pesquisar: ['todos', '']
            }
        default:
            return state

    }
}

export default filtroReducer