// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import livros from '../../views/home/store/reducer'
import leitura from '../../views/leitura/store/reducer'
import filtros from '../../views/home/filtros/store'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  livros,
  leitura,
  filtros
})

export default rootReducer
