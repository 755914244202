const estadoInicial = {
    layout: {
        footerProgressBarHidden: false,
        sideBarCapitulosHidden: true,
        navBarBooksHidden: true,
        menuHidden: true,
        navBarHide: false,
        navBarBooks: {},
        zoomIn: 0,
        skin: 'light'
    },
    leitura: [],
    tabs: [],
    activeTab: 0
}

const getLivroFromStore = (state, livroId) => {
    const livro = state.leitura.find(livro => livro.livroId === livroId);
    return livro
}

const leituraReducer = (state = estadoInicial, action) => {

    switch (action.type) {

        case 'SET_LAYOUT_LEITURA':
            return {
                ...state,
                layout: {
                    ...state.layout,
                    menuHidden: action.data.menuHidden,
                    navBarBooksHidden: action.data.navBarBooksHidden
                }
            }

        case 'SET_THEME':
            return {
                ...state,
                layout: {
                    ...state.layout,
                    skin: action.data.skin
                }
            }

        case 'GET_LIVRO_LEITURA':
            state.leitura.push(action.data);

            return {
                ...state
            }

        case 'REMOVE_LIVRO_LEITURA':
            state.leitura.splice(action.listaLeituraIndex, 1)

            return {
                ...state,
                leitura: state.leitura
            }

        case 'SET_LIVRO_ANOTACAO':
            const livroAnotacao = getLivroFromStore(state, action.data.livroId);
            livroAnotacao.anotacoes.push(action.data.info);

            return {
                ...state
            }

        case 'REMOVE_LIVRO_ANOTACAO':
            const removeLivroAnotacao = getLivroFromStore(state, action.data.livroId);
            const anotacaoIndex = removeLivroAnotacao.anotacoes.findIndex(anotacao => anotacao.lum_id === action.data.info.lum_id);
            removeLivroAnotacao.anotacoes.splice(anotacaoIndex, 1);
            
            return {
                ...state
            }

        case 'SET_LIVRO_MARCACAO':
            const livroMarcacao = getLivroFromStore(state, action.data.livroId);
            livroMarcacao.marcacoes.push(action.data.info);

            return {
                ...state
            }

        case 'UPDATE_LIVRO_MARCACAO':
            const livroId = action.data.info.lum_idLivro ? action.data.info.lum_idLivro : action.data.info.lum_idlivro
            const updateMarcacao = getLivroFromStore(state, livroId);
            const newMarcacao = updateMarcacao.marcacoes.find(marcacao => marcacao.lum_id === action.data.info.lum_id);
            newMarcacao.conteudo = action.data.conteudo;

            return {
                ...state
            }

        case 'REMOVE_LIVRO_MARCACAO':
            const removeLivroMarcacao = getLivroFromStore(state, action.data.livroId);
            const marcacaoIndex = removeLivroMarcacao.marcacoes.findIndex(marcacao => marcacao.lum_id === action.data.info.lum_id);
            removeLivroMarcacao.marcacoes.splice(marcacaoIndex, 1);

            return {
                ...state
            }

        case 'SET_LIVRO_BOOKMARK':
            const livroBookmarks = getLivroFromStore(state, action.data.livroId);
            livroBookmarks.bookmark = action.data.bookmarks;

            return {
                ...state
            }

        case 'SET_LIVRO_PAGINA_ATUAL':
            const livroPagina = getLivroFromStore(state, action.data.livroId);

            livroPagina['tempoLeitura'] = action.data.tempoLeitura;
            livroPagina['anotacoes'] = action.data.anotacoes;
            livroPagina['marcacoes'] = action.data.marcacoes;
            livroPagina['anotacoes'] = action.data.anotacoes;
            livroPagina['paginaLida'] = action.data.paginaLida;
            livroPagina['paginaAtual'] = action.data.paginaAtual;

            return {
                ...state
            }

        case 'SET_LIVRO_PAGINA_LIDA':
            const livroPaginaLida = getLivroFromStore(state, action.data.livroId);
            livroPaginaLida['paginaLida'] = action.data.paginaLida;

            return {
                ...state
            }

        case 'SET_TOTAL_PAGINAS':
            const leituraTotalPaginas = getLivroFromStore(state, action.data.livroId);
            leituraTotalPaginas.totalPaginas = action.data.totalPaginas;


            return {
                ...state
            }

        case 'GET_TABS':
            return {
                ...state,
                tabs: state.tabs
            }

        case 'SET_TABS':
            state.tabs.push(action.tab);
            state.activeTab = action.tab.indexPage;

            return {
                ...state
            }

        case 'CHANGE_TAB':
            state.activeTab = action.tabIndex;

            return {
                ...state
            }

        case 'REMOVE_TABS':
            state.tabs.splice(action.tabIndex, 1);

            return {
                ...state,
                tabs: state.tabs
            }

        case 'SET_HIDE_NAV_BAR':
            return {
                ...state,
                layout: {
                    ...state.layout,
                    navBarHide: action.navBarHide
                }

            }

        default:
            return state
    }
}

export default leituraReducer