const estadoInicial = {
  total: undefined,
  lista: [],
  categorias: [],
  detalhes: {},
  tempoLeitura: null
}

const livroReducer = (state = estadoInicial, action) => {

  switch (action.type) {
    case 'GET_LIVROS':
      state.lista = state.lista.concat(action.data.lista.data);
      return {
        ...state,
        params: action.params,
        total: action.data.lista.total,
        categorias: action.data.categorias,
        tempoLeitura: action.data.tempoLida
      }

    case 'GET_TEMPO_LEITURA':
        return {
          ...state,
          tempoLeitura: action.tempoLeitura
        }

    case 'ADICIONAR_REMOVER_FAVORITO':
      const index = state.lista.findIndex(livro => livro.liv_id === action.data.liv_id)
      state.lista[index].favorito = action.data.favorito

      return {
        ...state,
        lista: state.lista,
        detalhes: {
          ...state.detalhes,
          favorito: action.data.favorito
        }
      }

    case 'AVALIAR_LIVRO':
      return {
        ...state,
        ...state.lista,
        ...state.lista.map(livro => {
          if (livro.liv_id === action.data.liv_id) {
            livro.avaliacao = action.data.avaliacao
          }
        }
        ),
        detalhes: {
          ...state.detalhes,
          avaliacao: action.data.avaliacao
        }
      }
      
    default:
      return state
  }
}

export default livroReducer